import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import {
  FolderIcon,
  ArrowLeftIcon,
} from '@heroicons/react/24/outline'

function App() {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [detail, setDetail] = useState(false);
  const [detailTitle, setDetailTitle] = useState(null);
  const [filesDetail, setFilesDetail] = useState(null);
  const [subfolders, setSubfolders] = useState(null);

  const [aclCompany, setAclCompany] = useState(null);
  const [aclEmail, setAclEmail] = useState(null);

  const [acl, setAcl] = useState(false);

  const [error, setError] = useState("De correcte URL werd niet opgeroepen.");

  useEffect(() => {
    if (searchParams.get("id") && acl) {
      axios.get("https://beheer.docs.upgrade-estate.be/api/qrs" + searchParams.get("id") + "/?populate=Map&populate=Map.Files&populate=Map.Subfolders.Files&populate=Files")
        .then(res => {
          setData(res.data.data.attributes)
        })
        .catch(res =>
          setError(res.message)
        )
    }
  }, [acl])

  function viewFiles(title, files, subfolders=null) {
    setDetail(true)
    setDetailTitle(title)
    setFilesDetail(files)
    setSubfolders(subfolders)
  }

  function createACLEntry(company, email, id) {
    axios.post("https://beheer.docs.upgrade-estate.be/api/acls", {
      "data": {
        "Company": company,
        "Email": email,
        "qr": id
      }
    })
      .then(res => {
        setAcl(true)
      })
      .catch(res =>
        setError(res.message)
      )
  }

  return (
    <div className="bg-gray-100">
      <div className="w-3/4 mx-auto pt-10">
        <header className="">
          <span className="sr-only">Upgrade Estate</span>
          <img
            src="https://upgrade-estate.be/themes/custom/wax_ui_subtheme/logo.svg"
            alt=""
            className="h-24 w-auto"
          />
        </header>

        { acl && searchParams.get("id") ? 
          !detail ? (
          <main className="pt-16">
            {data && data.Title && data.Description ?
              <div className="">
                <h1 className="text-3xl font-bold">{data.Title}</h1>
                <p className="text-gray-400 pt-2"><ReactMarkdown>{data.Description}</ReactMarkdown></p>
                <h3 className="pt-4">Hieronder vind u aan deze QR-code gekoppelde PDF documenten en mappen met documenten terug.</h3>
              </div>
              :
              <div>
                <h1 className="text-3xl font-bold pt-28">De verzochte PDF's zijn niet gevonden.</h1>
                <h2>Probeer opnieuw te scannen.</h2>
                <p className="text-sm text-gray-400 pt-8">Technische gegevens: <span className="italic">{error}</span></p>
              </div>
            }


            {data && data.Files.data ?
              <div className="pt-12">
                <h3 className="text-xl font-semibold mb-2">Bestanden</h3>
                <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                  {data.Files.data.map((file) => (
                    <a href={"https://beheer.docs.upgrade-estate.be" + file.attributes.url} target="_blank">
                      <li key={file.id} className="relative">
                        <div className="group aspect-h-7 block w-full overflow-hidden rounded-lg bg-white border-dashed border-2 border-gray-600 border-spacing-4 hover:border-solid">
                          {file.attributes.type === "application/pdf" ?
                          <img src={"https://beheer.docs.upgrade-estate.be" + file.attributes.url} alt={file.attributes.alt} className="pointer-events-none object-cover group-hover:opacity-75" />
                          : file.attributes.type === "video/mp4" ?
                          <video poster={"https://beheer.docs.upgrade-estate.be" + file.attributes.url} alt={file.attributes.alt} className="pointer-events-none object-cover group-hover:opacity-75" />
                          : <img src={"https://beheer.docs.upgrade-estate.be" + file.attributes.url} alt={file.attributes.alt} className="pointer-events-none object-cover group-hover:opacity-75" />
                        }
                          <button type="button" className="absolute inset-0 focus:outline-none">
                            <span className="sr-only">View details for {file.attributes.caption}</span>
                          </button>
                        </div>
                        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.attributes.caption}</p>
                        <p className="pointer-events-none block text-sm font-medium text-gray-500"><b>{file.attributes.mime}</b> -  {file.attributes.size} MB</p>
                      </li>
                    </a>
                  ))}
                </ul>
              </div>
              :
              null
            }


            {data && data.Map.length > 0 ?
              <div className="pt-12 pb-12">
                <h3 className="text-xl font-semibold mb-2">Mappen</h3>
                <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                  {data.Map.map((map) => (
                    <button
                      type="button"
                      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-white"
                      onClick={() => viewFiles(map.Maptitel, map.Files, map.Subfolders)}
                    >
                      <FolderIcon className="h-16 w-16 text-gray-400 m-auto hover:text-gray-900" aria-hidden="true" />
                      <span className="mt-2 block text-sm font-semibold text-gray-900">{map.Maptitel}</span>
                    </button>

                  ))}
                </ul>
              </div>
              : null
            }


          </main>) 
          :
          (
            <main className="pt-16">
            
              <div className="">
                <h1 className="text-3xl font-bold">{data && data.Title ? data.Title : "?"}</h1>
                <p className="text-gray-400 pt-2">Map: <span className="font-bold">{detailTitle}</span></p>
                <button
                  type="button"
                  className="mt-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setDetail(false)}
                >
                  <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Terug naar overzicht
                </button>
              </div>

              <div className="pt-12">
              <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">

              {filesDetail.data.map((file) => (
                    <a href={"https://beheer.docs.upgrade-estate.be" + file.attributes.url} target="_blank">
                    <li key={file.id} className="relative">
                      <div className="group aspect-h-7 block w-full overflow-hidden rounded-lg bg-white border-dashed border-2 border-gray-600 border-spacing-4 hover:border-solid">
                        {file.attributes.type === "application/pdf" ?
                        <img src={"https://beheer.docs.upgrade-estate.be" + file.attributes.url} alt={file.attributes.alt} className="pointer-events-none object-cover group-hover:opacity-75" />
                        : file.attributes.type === "video/mp4" ?
                        <video poster={"https://beheer.docs.upgrade-estate.be" + file.attributes.url} alt={file.attributes.alt} className="pointer-events-none object-cover group-hover:opacity-75" />
                        : <img src={"https://beheer.docs.upgrade-estate.be" + file.attributes.url} alt={file.attributes.alt} className="pointer-events-none object-cover group-hover:opacity-75" />
                      }
                        <button type="button" className="absolute inset-0 focus:outline-none">
                          <span className="sr-only">View details for {file.attributes.caption}</span>
                        </button>
                      </div>
                      <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.attributes.caption}</p>
                      <p className="pointer-events-none block text-sm font-medium text-gray-500"><b>{file.attributes.mime}</b> -  {file.attributes.size} MB</p>
                    </li>
                  </a>
                  ))}
                  </ul>
              </div>
              
              {subfolders && subfolders.length > 0 ?
              <div className="pt-12 pb-12">
                <h3 className="text-xl font-semibold mb-2">Submappen</h3>
                <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                  {subfolders.map((map) => (
                    <button
                      type="button"
                      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-white"
                      onClick={() => viewFiles(map.Maptitel, map.Files)}
                    >
                      <FolderIcon className="h-16 w-16 text-gray-400 m-auto hover:text-gray-900" aria-hidden="true" />
                      <span className="mt-2 block text-sm font-semibold text-gray-900">{map.Maptitel}</span>
                    </button>

                  ))}
                </ul>
              </div>
              : null
            }

            </main>
          )
        : !acl && searchParams.get("id") ?
            <div>
              <h1 className="text-3xl font-bold pt-28">Gelieve uw gegevens na te laten.</h1>
              <h2>Deze gegevens worden enkel gebruikt ter autorizatie om bestanden in te laden.</h2>

              <form className="mt-8 space-y-6" action="#" method="POST">
                <input
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="company"
                  required
                  className="form-input block w-full md:w-2/3 px-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Bedrijf"
                  value={aclCompany}
                  onChange={e => setAclCompany(e.target.value)}
                />

                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  required
                  className="form-input block w-full md:w-2/3 px-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="E-mail"
                  value={aclEmail}
                  onChange={e => setAclEmail(e.target.value)}
                />

                <a
                  autoFocus
                  type="submit"
                  className="group relative w-full md:w-2/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  onClick={() => createACLEntry(aclCompany, aclEmail, searchParams.get("id").split("/")[1])}
                >
                  Verzenden
                </a>
              </form>  
            </div>
        :
        <div>
          <h1 className="text-3xl font-bold pt-28">De correcte URL werd niet opgeroepen.</h1>
          <h2>Probeer opnieuw te scannen.</h2>
        </div>
        }
      </div>
    </div>
  );
}

export default App;
